import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/jFHq78wWhb4"
    bibleGroupSrc="https://www.docdroid.net/kkprqgb/bible-group-homework-9-27-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/41753188"
  >
    <SEO title="Together for the Gospel - Together is Better" />
  </Layout>
)

export default SermonPost
